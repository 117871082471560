import { Component, Inject, OnDestroy } from '@angular/core';
import { DashboardService, DialogRef, FILTER_OPTIONS_ENUMS } from '@app/services';
import { DatePipe } from '@angular/common';
import { DIALOG_DATA } from '@app/helpers/dialog-tokens';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-filters-modal',
    templateUrl: './filters-modal.component.html',
    styleUrls: ['./filters-modal.component.scss'],
})
export class FiltersModalComponent implements OnDestroy {
    heading: string;
    selected: number;
    fields: {
        id: string;
        fieldName: string;
        options: string[];
        type: string;
        transformedOptions?: { name: string }[];
    }[] = [];

    formGroup: FormGroup = new FormGroup({});
    filtersAppliedCount = 0;
    matrix = [];
    headerText = '';

    private valueChangesSubscription: Subscription;

    constructor(
        @Inject(DIALOG_DATA) public data,
        private dialogRef: DialogRef,
        private datePipe: DatePipe,
        private _dashboardService: DashboardService
    ) {
        this.formGroup.valueChanges.subscribe(() => {
            this.filtersAppliedCount = this._dashboardService.updateFiltersAppliedCount(
                this.formGroup.value,
                this.fields.find((field) => field.fieldName === 'Payor').transformedOptions
            );
            this.buildHeaderTitle();
        });

        this.headerText = `Filters`;
        this.heading = this.headerText;

        this.filtersAppliedCount = data.filtersAppliedCount;

        // attach count for header, if any
        if (this.filtersAppliedCount) {
            this.buildHeaderTitle();
        }

        if (data.fields) {
            this.fields = data.fields;
            this.fields.forEach((field) => {
                const defaultValue = data.defaultValues[field.id];

                if (field.fieldName.toLowerCase() !== 'payor') {
                    // 'payor' is already in right format (comes with 'id')
                    // transform options because 'app-checkbox-multi-select' expects options in a object format
                    field.transformedOptions = this.transformOptions(field.options);
                } else {
                    field.transformedOptions = field.options as unknown as { id: null; name: string }[];
                }

                this.formGroup.addControl(field.fieldName, new FormControl(defaultValue || ''));
            });
        }
    }

    /**
     * Transforms an array of strings into an array of objects with a `name` property to be accepted by 'app-checkbox-multi-select'.
     *
     * This function is useful when you need to convert a list of simple string options
     * into a more complex structure that can be easily used in components or services
     * that expect objects with specific properties.
     *
     * @param options - An array of strings representing the options to be transformed.
     * @returns An array of objects, each containing a `name` property with the corresponding string value.
     */
    transformOptions(options: string[]): { id: null; name: string }[] {
        return options.map((option) => ({ id: null, name: option }));
    }

    objectKeys(obj: { [key: string]: any }): string[] {
        return Object.keys(obj);
    }

    close(payload = null) {
        if (payload) {
            this.dialogRef.close(payload);
            return;
        }

        this.dialogRef.close();
    }

    viewResults() {
        const filters = this.formGroup.value;
        this.close(filters);
    }

    buildHeaderTitle() {
        if (this.filtersAppliedCount) {
            this.heading = this.headerText + ` (${this.filtersAppliedCount})`;
            return;
        }

        this.heading = this.headerText;
    }

    clearAll() {
        this.fields.forEach((field) => {
            if (field.type === 'select') {
                this.formGroup.get(field.fieldName).patchValue(field.options[0]);
            }
            if (field.fieldName === 'Payor') {
                this.formGroup.get(field.fieldName).patchValue(FILTER_OPTIONS_ENUMS.ALL);
            }
        });
    }

    ngOnDestroy() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }
}
