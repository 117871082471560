<div
    [class]="'form-group align-items-center justify-content-center ' + formGroupClass"
    [class.select-error]="hasError"
    [style]="formGroupStyle"
    [ngClass]="{ 'd-flex align-items-center mb-0': labelLeft }"
>
    <div class="d-flex label-cont">
        <label
            [ngClass]="[labelClass, 'd-block', subLabel ? 'label-with-subLabel' : '', labelLeft ? 'mx-0 my-0' : '']"
            *ngIf="label"
            >{{ label | translate }} {{ isRequired && !subLabel ? '*' : '' }}</label
        ><span *ngIf="!!subLabel" class="text-secondary">{{ subLabel }}</span>
    </div>
    <div ngbDropdown autoClose="outside">
        <div
            [ngClass]="{ 'disabled disabled-background-color': disabled }"
            ngbDropdownToggle
            [class]="backgroundClass"
            class="cursor-pointer form-control rounded-curve d-flex"
            id="facilityDropdown"
            style="border: 1px solid rgba(28, 55, 117, 0.15) !important"
        >
            <div
                [style]="
                    ' text-overflow: ellipsis; overflow: hidden !important; white-space: nowrap;  font-weight: 500; width:90%; padding:6px 10px'
                "
            >
                <div *ngIf="showSelectedOptions">
                    <div
                        *ngFor="let option of selectedOptions"
                        class="badge badge-pill badge-primary d-inline-flex flex-row align-items-center justify-content-between pill"
                    >
                        <span
                            class="body-medium text-white pill-text"
                            [ngClass]="{
                                'options-disabled': isDisabled(option.id)
                            }"
                        >
                            {{ option.name || option | translate }}
                        </span>

                        <span
                            *ngIf="isDisabled(option.id)"
                            (click)="selectOption(option); $event.stopPropagation()"
                            class="material-icons-outlined close-icon"
                        >
                            close
                        </span>
                    </div>
                </div>
                <div *ngIf="!showSelectedOptions && selectedOptions.length">
                    <span class="text-secondary">{{ '(' + selectedOptions.length + ') selected' }}</span>
                </div>
                <!-- <span *ngFor="let option of selectedOptions; let i = index"
                >{{ option.name || option | translate }} <span *ngIf="selectedOptions.length - 1 != i">,</span>
            </span> -->
                <span class="choose-option" *ngIf="selectedOptions.length < 1">{{ placeholder }}</span>
            </div>
            <div class="expand-more">
                <span class="material-symbols-outlined" style="float: right"> expand_more </span>
            </div>
        </div>
        <div
            class="list-holder"
            ngbDropdownMenu
            aria-labelledby="facilityDropdown"
            [ngClass]="{ 'search-padding': searchEnabled }"
        >
            <app-search
                *ngIf="searchEnabled"
                (search)="searchOptions($event)"
                [placeholder]="placeHolder"
                wrapperClass="mb-2 bg-body-background"
                inputClass="bg-body-background"
                [autocomplete]="'off'"
            ></app-search>
            <div class="list-dropdown">
                <ul>
                    <!-- show 'ALL' option when search is not applied -->
                    <ng-container *ngIf="isSelectAllEnabled && !searchMode">
                        <li
                            class="bg-body-background cursror-pointer"
                            [class.disabled]="filteredOptions.length === disabledOptions.length"
                            [class.active]="true"
                            (click)="selectAllOptions(option, $event)"
                            ngbDropdownItem
                        >
                            <input
                                type="checkbox"
                                [checked]="selectALLCheckBox"
                                [disabled]="filteredOptions.length === disabledOptions.length"
                                id="custom-all-option"
                                class="mr-2 checkbox"
                                (change)="checkboxSelectAllClicked($event)"
                            />
                            <label class="body-small-bold text-secondary m-0" for="custom-all-option">{{
                                selectAllLabel
                            }}</label>
                        </li>
                    </ng-container>
                    <ng-container *ngFor="let option of filteredOptions">
                        <li
                            class="bg-body-background cursror-pointer"
                            [class.disabled]="isDisabled(option.id)"
                            [class.active]="checkActive(option)"
                            (click)="selectOption(option)"
                            ngbDropdownItem
                            *ngIf="hideDisabledOptions ? !isDisabled(option.id) : true"
                        >
                            <!-- {{ option.name || option | translate }} -->
                            <input
                                type="checkbox"
                                [checked]="checkActive(option)"
                                [disabled]="isDisabled(option.id)"
                                id="{{ option.name }}"
                                class="mr-2 checkbox"
                                (change)="onCheckboxChange(option, $event)"
                            />
                            <label class="body-small-bold text-secondary m-0" for="{{ option.name }}">{{
                                option.name
                            }}</label>
                        </li>
                    </ng-container>
                </ul>
                <span
                    *ngIf="filteredOptions.length < 1 || disabledOptions.length === selectOptions.length"
                    class="text-center d-block pt-3 not-found"
                    >No Option Found</span
                >
            </div>
        </div>
    </div>
</div>
