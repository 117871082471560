<app-base-modal-card [heading]="heading" wrapperClass="modal-medium" (close)="close()">
    <div body class="px-3 rounded">
        <form [formGroup]="formGroup">
            <div *ngFor="let field of fields; let i = index">
                <ng-container *ngIf="field.type === 'select'">
                    <app-select
                        class="ml-2"
                        [label]="field.fieldName"
                        [formControl]="formGroup.get(field.fieldName)"
                        [options]="field.transformedOptions"
                        inputClass="bg-white filter-select cursor-pointer sm"
                        [disabled]="loader"
                    ></app-select>
                </ng-container>

                <ng-container *ngIf="field.type === 'multi-select'">
                    <app-checkbox-multi-select
                        [isSelectAllEnabled]="true"
                        [selectAllLabel]="'ALL'"
                        [selectALLByDefault]="true"
                        [label]="field.fieldName"
                        class="col-4"
                        [formControl]="formGroup.get(field.fieldName)"
                        [options]="field.transformedOptions"
                        [showSelectedOptions]="false"
                        placeHolder="Select {{ field.fieldName }}"
                        formGroupClass="m-0"
                        [searchEnabled]="true"
                        inputClass="bg-white filter-select cursor-pointer sm"
                    ></app-checkbox-multi-select>
                </ng-container>
            </div>
        </form>
    </div>
    <div footer>
        <button (click)="clearAll()" class="btn btn-outline-primary rounded-pill">
            Clear All <ng-container *ngIf="filtersAppliedCount">({{ filtersAppliedCount }})</ng-container>
        </button>
        <button (click)="viewResults()" class="ml-2 btn btn-outline-secondary rounded-pill">View Results</button>
    </div>
</app-base-modal-card>
