/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { FILTER_OPTIONS_ENUMS } from '@app/services';

@Component({
    selector: 'app-checkbox-multi-select',
    templateUrl: './checkbox-multi-select.component.html',
    styleUrls: ['./checkbox-multi-select.component.scss'],
})
export class CheckboxMultiSelectComponent implements OnChanges, ControlValueAccessor, OnInit {
    @Input() selectedOptions = [];
    filteredOptions: any = [];

    @Input('options') selectOptions = [];
    @Input() label: string;
    @Input() subLabel: string;
    @Input() placeholder = 'Choose options';
    @Input() placeHolder = 'Search options';
    @Input() backgroundClass: string;
    @Input() foregroundClass: string;
    @Input() class: string;
    @Input() labelClass = '';
    @Input() inputClass = '';
    @Input() formGroupClass = '';
    @Input() formGroupStyle = '';
    @Input() selectStyle = '';
    @Input() labelLeft = false;
    @Input() selected = '';
    @Input() customSelect = false;
    @Input() disabledOptions = []; //if someone wants to disabled any selected option to remove
    @Input() showSelectedOptions = true;
    @Input() hideDisabledOptions = false;
    @Input() isSelectAllEnabled = false;
    @Input() selectAllLabel = '';
    @Input() searchEnabled = true;
    @Input() selectALLByDefault = true;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    propagateChange = (_: any) => {};
    onTouched = () => {};

    value: any = '';
    selectALLCheckBox: boolean;
    searchMode = false; // 'true' when searching...
    @Input() disabled: boolean;
    @Output() optionsSelected = new EventEmitter();
    constructor(public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        const control: AbstractControl = this.ngControl.control;

        if (this.ngControl.control.value === this.selectAllLabel) {
            this.selectedOptions = this.selectOptions.filter((option) => option.id !== FILTER_OPTIONS_ENUMS.NO_PAYOR);
            this.updateSelectALLCheckBox();
            this.selectAllOptions(null, null);
        }

        if (this.ngControl.control.value) {
            // case: selects options coming in the control on first load
            this.controlValueChangesCallback(this.ngControl.control.value);
        }

        control.valueChanges.subscribe((value) => {
            this.controlValueChangesCallback(value);
        });
    }

    controlValueChangesCallback(value) {
        if (this.ngControl.control.value === this.selectAllLabel) {
            this.selectedOptions = this.selectOptions.filter((option) => option.id !== FILTER_OPTIONS_ENUMS.NO_PAYOR);
            this.updateSelectALLCheckBox();
            this.selectAllOptions(null, null);
            return;
        }

        if (Array.isArray(value)) {
            this.selectedOptions = [];
            value.forEach((v) => {
                const option = this.selectOptions.find((opt) => (opt.id ? opt.id == v : opt == v));
                if (option) {
                    this.selectedOptions.push(option);
                }
            });
        } else if (value) {
            this.selectedOptions = [];
            this.selectedOptions = this.selectOptions.filter((opt) => value.includes(opt.id));
        }

        this.updateSelectALLCheckBox();
    }

    ngOnChanges(): void {
        this.filteredOptions = [...this.selectOptions];
    }
    searchOptions(search: string) {
        if (typeof search === 'string') {
            this.searchMode = true;
            this.filteredOptions = this.selectOptions.filter(
                (op) =>
                    (op.name && op.name.toLowerCase().includes(search?.toLowerCase())) ||
                    (!op.name && op.toLowerCase().includes(search?.toLowerCase()))
            );
            if (search === '') {
                this.searchMode = false;
            }
        } else {
            this.filteredOptions = this.selectOptions;
            this.searchMode = false;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectAllOptions(_options = null, event: Event) {
        const checkbox = event?.target as HTMLInputElement;
        if (checkbox && checkbox.checked === undefined) {
            this.selectALLCheckBox = true;
        }

        const selectedIds = this.selectedOptions
            .map((option) => option.id)
            .filter((option) => option !== FILTER_OPTIONS_ENUMS.NO_PAYOR);
        const unSelectedActiveIds = this.selectOptions
            .filter((option) => !(this.disabledOptions.includes(option.id) || selectedIds.includes(option.id)))
            .filter((option) => option.id !== FILTER_OPTIONS_ENUMS.NO_PAYOR)
            .map((option) => option.id);

        this.selectedOptions = this.selectedOptions.filter((option) => option.id !== FILTER_OPTIONS_ENUMS.NO_PAYOR);

        this.propagateChange([...selectedIds, ...unSelectedActiveIds]);
        this.optionsSelected.emit([...selectedIds, ...unSelectedActiveIds]);
    }
    selectOption(option: any) {
        if (this.isDisabled(option.id)) return;

        if (this.selectALLCheckBox) this.selectALLCheckBox = false;

        // custom code for FILTER_OPTIONS_ENUMS.NO_PAYOR case for 'minutes-by-patient.component.ts'
        if (this.noPayorCase(option)) {
            return;
        }

        const index = this.selectedOptions.findIndex(
            (op) => (op.id && op.id === option.id) || (!op.id && op == option)
        );
        let options = [];
        if (index > -1) {
            this.selectedOptions.splice(index, 1);
            options = [...this.selectedOptions];
        } else {
            options = [...this.selectedOptions, option];
        }

        if (option.id) {
            const ids = options.map((option) => option.id);
            this.propagateChange(ids);
            this.optionsSelected.emit(ids);
        } else {
            this.optionsSelected.emit(options);
            this.propagateChange(options);
        }

        this.updateSelectALLCheckBox();
    }

    updateSelectALLCheckBox() {
        if (
            this.selectedOptions.length ===
            this.selectOptions.filter((option) => option.id !== FILTER_OPTIONS_ENUMS.NO_PAYOR).length
        ) {
            this.selectALLCheckBox = true;

            return;
        }

        this.selectALLCheckBox = false;
    }

    checkboxSelectAllClicked(event: Event) {
        // checkbox is clicked
        const checkbox = event.target as HTMLInputElement;
        if (checkbox.checked === true) {
            this.selectALLCheckBox = true;
            this.selectAllOptions(this.selectOptions, event);
        } else {
            // unchecked 'ALL' checkbox
            this.selectedOptions = [];
            this.propagateChange([]);
            this.optionsSelected.emit([]);
        }
    }
    onCheckboxChange(option, event: Event) {
        if (option.id === FILTER_OPTIONS_ENUMS.NO_PAYOR) {
            const checkbox = event.target as HTMLInputElement;
            if (!checkbox.checked) {
                this.selectedOptions = [];
                this.propagateChange([]);
                this.optionsSelected.emit([]);
            }
        }
    }
    noPayorCase(option: any): boolean {
        if (option.id !== FILTER_OPTIONS_ENUMS.NO_PAYOR) {
            this.selectedOptions = this.selectedOptions.filter((op) => op.id !== FILTER_OPTIONS_ENUMS.NO_PAYOR);
            return false; // normal flow
        }

        const options = [option];
        this.selectedOptions = options;

        if (option.id) {
            const ids = options.map((option) => option.id);
            this.propagateChange(ids);
            this.optionsSelected.emit(ids);
        } else {
            this.optionsSelected.emit(options);
            this.propagateChange(options);
        }

        return true;
    }
    checkActive(option: any) {
        return this.selectedOptions.find((op) => (op.id && op.id === option.id) || (!op.id && op == option))
            ? true
            : false;
    }
    isDisabled(optionId: any): boolean {
        return this.disabledOptions.findIndex((disabledOption) => disabledOption === optionId) !== -1;
    }
    get isRequired(): boolean {
        const control: AbstractControl = this.ngControl.control;

        if (!control || (control && control.validator === null)) return false;
        return control.validator(control)?.required ?? false;
    }

    get hasError(): boolean {
        const control: AbstractControl = this.ngControl.control;
        if (control && control.touched && control.invalid) return true;
        return false;
    }
    writeValue(obj: any): void {
        if (obj) {
            this.value = obj;
            this.selectedOptions = this.selectOptions.filter((option) => this.value.includes(option.id));
        } else this.value = '';
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
