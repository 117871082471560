export function isDateInRange(date: Date, minDate: Date, maxDate: Date) {
    if (
        !date || !minDate || !maxDate ||
        new Date(date).toISOString() > new Date(maxDate).toISOString() ||
        new Date(date).toISOString() < new Date(minDate).toISOString()
    ) {
        return false;
    }
    return true;
}

/**
 * function will check if time of date is in range
 * @param {Date} checkTime - time to check in range
 * @param {Date} startTime - start time of the range
 * @param {Date} endTime - end time of range
 */
export function isTimeInRange(checkDate, startDate, endDate) {
    try {
        const checkTime = new Date(checkDate);
        const startTime = new Date(startDate);
        const endTime = new Date(endDate);
    
        const checkHours = checkTime.getHours();
        const checkMinutes = checkTime.getMinutes();
        
        const startHours = startTime.getHours();
        const startMinutes = startTime.getMinutes();
        
        const endHours = endTime.getHours();
        const endMinutes = endTime.getMinutes();
    
        const checkTotalMinutes = checkHours * 60 + checkMinutes;
        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;
    
        return checkTotalMinutes >= startTotalMinutes && checkTotalMinutes <= endTotalMinutes;
    } catch (error) {
        return false;
    }
}
/**
 * This method will ensure that newStart and newEnd are not overlapping with existingStart and existingEnd
 */
export function isTimeOverlapping(newStart, newEnd, existingStart, existingEnd): boolean {
    try {
        const newStartTime = new Date(newStart);
        const newEndTime = new Date(newEnd);
        const existingStartTime = new Date(existingStart);
        const existingEndTime = new Date(existingEnd);

        // Convert all times to total minutes from the start of their respective days
        const newStartMinutes = newStartTime.getHours() * 60 + newStartTime.getMinutes();
        let newEndMinutes = newEndTime.getHours() * 60 + newEndTime.getMinutes();
        const existingStartMinutes = existingStartTime.getHours() * 60 + existingStartTime.getMinutes();
        let existingEndMinutes = existingEndTime.getHours() * 60 + existingEndTime.getMinutes();

        // Handle cases where the existing end time crosses midnight
        if (existingEndTime.getDate() > existingStartTime.getDate()) {
            existingEndMinutes += 24 * 60; // Add 24 hours worth of minutes
        }

        // Handle cases where the new end time crosses midnight
        if (newEndTime.getDate() > newStartTime.getDate()) {
            newEndMinutes += 24 * 60;
        }

        // Case 1: New start or end falls within the existing range
        const isStartTimeInRange = newStartMinutes >= existingStartMinutes && newStartMinutes <= existingEndMinutes;
        const isEndTimeInRange = newEndMinutes >= existingStartMinutes && newEndMinutes <= existingEndMinutes;

        // Case 2: New range fully covers the existing range
        const isNewRangeCoveringExisting =
            newStartMinutes <= existingStartMinutes && newEndMinutes >= existingEndMinutes;

        return isStartTimeInRange || isEndTimeInRange || isNewRangeCoveringExisting;
    } catch (error) {
        return false;
    }
}

