import { Component } from '@angular/core';
import { DeleteSectionColumn } from '@app/data-grid/interfaces/grid-row-action';
import { DeletionConfirmationDialogComponent } from '@app/shared/deletion-confirmation-dialog/deletion-confirmation-dialog.component';
import { ModalService } from '@app/services/modal.service';
import { GridActionsService } from '@app/data-grid/services/grid-actions.service';
import { ROUTER_UTILS, Role } from '@app/helpers';
import { AuthService, NotificationService, ToasterService } from '@app/services';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ConflictModalComponent } from '@app/shared/conflict-modal/conflict-modal.component';

@Component({
    selector: 'app-actions-cell-renderer',
    templateUrl: './actions-cell-renderer.component.html',
    styleUrls: ['./actions-cell-renderer.component.scss'],
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {
    params: any;
    checked = false;
    dailyNoteViewMode =
        '/' +
        ROUTER_UTILS.config.documentationViewMode.root +
        '/' +
        ROUTER_UTILS.config.documentationViewMode.dailyNoteDoc.root;
    notifyDisabled: boolean;
    disabledDeleteMessage: string

    isActionAllowed = true;
    constructor(
        private gridActionService: GridActionsService,
        private authService: AuthService,
        private modalService: ModalService,
        private toasterService: ToasterService,
        private notificationService: NotificationService
    ) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
        // eslint-disable-next-line spellcheck/spell-checker
        this.disabledDeleteMessage = params.data?.disabledDeleteMessage;
        if(params?.data?.mode == 'Concurrent' || params?.data?.mode == 'Co-Treat') {
            this.isActionAllowed = params.data.isAllowed;
        }
    }
    canEdit() {
        if (this.params.gridName == 'users') {
            const role = this.params.data.roles;
            if (this.authService.isSuperAdmin) {
                return this.params.edit;
            } else if (this.authService.isSystemAdmin) {
                return (
                    (this.authService.accountValue.user.id == this.params.data._id || this.params.edit) &&
                    !role.includes(Role.SuperAdmin)
                );
            } else if (this.authService.isManager) {
                return (
                    this.authService.accountValue.user.id == this.params.data._id ||
                    (this.params.edit &&
                        !role.includes(Role.SystemAdmin) &&
                        !role.includes(Role.SuperAdmin) &&
                        (role.includes(Role.Therapist) || role.includes(Role.Physician)))
                );
            } else {
                return this.authService.accountValue.user.id == this.params.data._id;
            }
        } else {
            return this.params.edit;
        }
    }
    canDelete() {
        if (this.params.gridName == 'roles') {
            return this.params.data.name != this.params.data.baseRole;
        } else if (this.params.gridName == 'users') {
            return false;
            const role = this.params.data.roles;
            if (this.authService.isSuperAdmin) {
                return this.params.delete;
            } else if (this.authService.isSystemAdmin) {
                return (
                    (this.authService.accountValue.user.id == this.params.data._id || this.params.delete) &&
                    !role.includes(Role.SuperAdmin)
                );
            } else if (this.authService.isManager) {
                return (
                    this.authService.accountValue.user.id == this.params.data._id ||
                    (this.params.delete &&
                        !role.includes(Role.SystemAdmin) &&
                        !role.includes(Role.SuperAdmin) &&
                        (role.includes(Role.Therapist) || role.includes(Role.Physician)))
                );
            } else {
                return this.authService.accountValue.user.id == this.params.data._id;
            }
        } else {
            return this.params.delete;
        }
    }
    refresh() {
        return false;
    }
    invokeClickAction() {
        this.params.context.componentParent.deleteIconRendererClick({ data: this.params.data, value: '' });
    }

    actionClicked(event: any, action: string) {
        event.preventDefault();
        event.stopPropagation();
        const { data, rowIndex, gridName } = this.params;
        if (action === 'delete' && DeleteSectionColumn.includes(gridName.toLowerCase())) {
            const dialogRef = this.modalService.open(DeletionConfirmationDialogComponent, {
                data: { heading: 'Deletion' },
            });
            dialogRef.afterClosed().subscribe((isDeleted) => {
                if (isDeleted) {
                    this.gridActionService.setSelectedAction(action, data, rowIndex, gridName);
                }
            });
            return;
        }
        this.gridActionService.setSelectedAction(action, data, rowIndex, gridName);
    }

    conflictClicked() {
        this.modalService.open(ConflictModalComponent, {
            data: this.params?.data,
        });
    }

    notifyToCorrect() {
        if (!this.params.data) {
            console.log('err: data is empty');
            return;
        }

        // patient Name missing
        const {
            therapistId,
            discipline,
            patientName,
            patientId,
            dailyNoteId,
            therapistName,
            day,
            therapistUserId,
            message,
            facilityAd,
            therapyCase,
        } = this.params.data;

        if (
            !therapistId ||
            !discipline ||
            !patientName ||
            !patientId ||
            !therapistName ||
            !day ||
            !therapistUserId ||
            !message ||
            !facilityAd
        ) {
            this.toasterService.show({
                title: 'Error',
                body: 'Missing Details Needed for notifying therapist',
                type: 'error',
            });
            return;
        }

        if (!dailyNoteId) {
            // CASE: Mentioned daily note in coTreatment doesn't exist yet.
            // all other field are available except 'dailyNote' & 'therapyCase'

            const payload = {
                to: therapistUserId,
                from: this.authService.accountValue.user.id,
                facility: JSON.parse(localStorage.getItem('current-facility')).id,
                data: {
                    title: `Co-Treatment Conflict: ${patientName}`,
                    message: message,
                    url: '/minutes-by-patient',
                },
                isRead: false,
                isArchive: false,
            };

            // to avoid sending notification twice, disable the button
            this.notifyDisabled = true;
            this.notificationService.createNotification(payload).subscribe(() => {
                this.notifyDisabled = false;

                this.toasterService.show({
                    title: 'Notified!',
                    body: 'Notification Successfully Sent to Therapist.',
                    type: 'success',
                });
                return;
            });
            return;
        }

        const payload = {
            to: therapistUserId,
            from: this.authService.accountValue.user.id,
            facility: JSON.parse(localStorage.getItem('current-facility')).id,
            data: {
                title: `Co-Treatment Conflict: ${patientName}`,
                message: message,
                url:
                    this.dailyNoteViewMode +
                    '?patient=' +
                    patientId +
                    `&therapy-case=${therapyCase}&facilityAd=${facilityAd}&doc_type=Daily%20Note&discipline=${discipline}&doc=${dailyNoteId}&tab=documentation&dueDate=${day}`,
            },
            isRead: false,
            isArchive: false,
        };

        // to avoid sending notification twice, disable the button
        this.notifyDisabled = true;
        this.notificationService.createNotification(payload).subscribe(() => {
            this.notifyDisabled = false;

            this.toasterService.show({
                title: 'Notified!',
                body: 'Notification Successfully Sent to Therapist.',
                type: 'success',
            });
            return;
        });
    }
}
