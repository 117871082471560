import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { facilityAdmissionRemovedAction } from '@app/store/actions/index';

import { environment } from '@environments/environment';
import { FacilityAdmission } from '@app/models';
import { Store } from '@ngrx/store';
import { PatientState } from '@app/store/store.interface';
import { DOCUMENTATION_CONSTANTS } from '@app/interfaces/documentation';

const baseUrl = `${environment.apiUrl}/facility-admission`;

@Injectable({ providedIn: 'root' })
export class FacilityAdmissionService {
    tab$: Observable<any>;
    hasActiveFacilityAdmission: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(private router: Router, private http: HttpClient, private store: Store<{ patient: PatientState }>) {}

    facilityAdmissionSave(facilityAdmissionData: FacilityAdmission) {
        return this.http.post<any>(`${baseUrl}`, facilityAdmissionData, { withCredentials: true }).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    getPlannerPatients(facilityId) {
        return this.http.get<any>(`${baseUrl}/planner/${facilityId}`, { withCredentials: true }).pipe(
            map((patients) => {
                return patients.data;
            })
        );
    }
    getPlannerFacilityStats(facilityId) {
        return this.http
            .get<any>(`${baseUrl}/planner/refactor/facility-stats/${facilityId}`, { withCredentials: true })
            .pipe(
                map((patients) => {
                    return patients.data;
                })
            );
    }
    getPlannerPatients_refactor(facilityId) {
        return this.http.get<any>(`${baseUrl}/planner/refactor/${facilityId}`, { withCredentials: true }).pipe(
            map((patients) => {
                return patients.data;
            })
        );
    }
    getPlannerPatientByFacilityAdmission(facilityAdmission_id) {
        return this.http
            .get<any>(`${baseUrl}/planner/patient/refactor/${facilityAdmission_id}`, { withCredentials: true })
            .pipe(
                map((patients) => {
                    return patients.data;
                })
            );
    }
    getFacilityAdmission(
        patientId: string,
        facility: string,
        facilityAdmissionId,
        facilitiesWithoutRestriction = false
    ) {
        return !facilityAdmissionId
            ? this.getActiveFacilityAdmissionOnPatientId(patientId, facility, facilitiesWithoutRestriction)
            : this.getOneFacilityAdmission(facilityAdmissionId);
    }

    getActiveFacilityAdmissionOnPatientId(patientId: string, facility: string, facilitiesWithoutRestriction = false) {
        return this.http.get<any>(`${baseUrl}/patient/${patientId}/facility/${facility}`, {
            params: {
                facilitiesWithoutRestriction
            }
        }).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    getOneFacilityAdmission(facilityAdmissionId: string) {
        return this.http.get<any>(`${baseUrl}/` + facilityAdmissionId).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    facilityAdmissionUpdate(facilityAdmissionData: FacilityAdmission) {
        return this.http.patch<any>(`${baseUrl}`, facilityAdmissionData, { withCredentials: true }).pipe(
            map((facilityAdmission) => {
                return facilityAdmission.data;
            })
        );
    }
    facilityUpdateWithFiles(facility: { facilityAdmission: string; filesAndDocuments: any }) {
        return this.http.patch(`${baseUrl}/files`, facility, { withCredentials: true });
    }
    facilityAdmissionUpdateFile(facilityAndFile: { facilityAdmissionId: string; filesAndDocumentsId: string, fileName: string }) {
        return this.http.patch(`${baseUrl}/files-update`, facilityAndFile, { withCredentials: true });
    }
    facilityAdmissionRemovedService() {
        return this.store.dispatch(facilityAdmissionRemovedAction());
    }
    facilityAdmissionDischarged(facilityAdmission: FacilityAdmission) {
        return facilityAdmission.patientDischarge && facilityAdmission.patientDischarge.facilityDischargeDate;
    }

    getFacilityAdmissionByPatientId(
        patientId: string,
        activeAdmission = false
    ): Observable<{ data: FacilityAdmission; success: boolean; message: string }> {
        let url = `${baseUrl}/patient/${patientId}?facilityId=${
            JSON.parse(localStorage.getItem('current-facility')).id
        }`;

        if (activeAdmission) {
            url = `${url}&activeAdmission=true`;
        }

        return this.http.get<{ data: FacilityAdmission; success: boolean; message: string }>(url);
    }

    updateMedicalDiagnosis(payload: { facilityAdmissionId: string; icd10Codes: any }): Observable<any> {
        return this.http.patch(`${baseUrl}/medical-diagnosis`, payload);
    }

    
/**
 * Finds the latest completed note date from all therapy admissions and disciplines.
 * 
 * - Supports both a single `therapyAdmission` object or an array of admissions.
 * - Includes `COMPLETED`, `REFUSED`, and `WITHHELD` notes when determining the latest date.
 * - If `facilityAdmissionAdmitDate` is provided, it sets a baseline for the earliest possible discharge date.
 * - If `facilityAdmissionAdmitDate` is not provided, defaults to the earliest SOCDate or today's date.
 * - Ensures valid handling of missing, empty, or undefined `therapyAdmissions` to prevent errors.
 * 
 * @param therapyAdmissions - A single therapy admission object or an array of therapy admissions.
 * @param minDate - (Optional) The facility admission date or min discharge date to set the baseline.
 * @returns The latest completed note date or the determined end-of-care date.
 */
    getLatestCompletedNoteDate(therapyAdmissions?: any | any[], minDate?: string|Date): Date {
        if (!therapyAdmissions) {
            return minDate ? new Date(minDate) : new Date();
        }
    
        const today: Date = new Date();
        let minEndOfCareDate: Date = minDate ? new Date(minDate) : today;
    
        // Ensure therapyAdmissions is always an array for consistent processing
        const admissionsArray = Array.isArray(therapyAdmissions) ? therapyAdmissions : [therapyAdmissions];
    
        const completedStatuses = [
            DOCUMENTATION_CONSTANTS.COMPLETED,
            DOCUMENTATION_CONSTANTS.REFUSED,
            DOCUMENTATION_CONSTANTS.WITHHELD,
        ];
    
        admissionsArray.forEach((therapyAdmission) => {
            if (!therapyAdmission || !therapyAdmission.documentation) return;
    
            // Set initial minEndOfCareDate based on SOCDate
            minEndOfCareDate = new Date(therapyAdmission.SOCDate) > today ? minEndOfCareDate : therapyAdmission.SOCDate;
    
            for (const key in therapyAdmission.documentation) {
                if (key !== 'addendum' && key !== 'dischargeNote') {
                    const documentationEntries = therapyAdmission.documentation[key];
    
                    if (Array.isArray(documentationEntries)) {
                        documentationEntries.forEach((item) => {
                            if (item.day && completedStatuses.includes(item.status)) {
                                if (new Date(item.day) > new Date(minEndOfCareDate)) {
                                    minEndOfCareDate = item.day;
                                }
                            }
                        });
                    } else if (documentationEntries && documentationEntries.day && completedStatuses.includes(documentationEntries.status)) {
                        if (new Date(documentationEntries.day) > new Date(minEndOfCareDate)) {
                            minEndOfCareDate = documentationEntries.day;
                        }
                    }
                }
            }
        });
    
        return minEndOfCareDate;
    }
}
