import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionsCellRendererComponent } from '@app/data-grid/cell-renderers';
import { IntegrationEvents } from '@app/helpers/constants';
import { ExportLogs, InitialImportLogs, MigrationStatuses } from '@app/interfaces';
import { environment } from '@environments/environment';
import { ColDef } from 'ag-grid-community';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const baseUrl = `${environment.apiUrl}/migration`;

@Injectable({
    providedIn: 'root',
})
export class MigrationService {
    initialLoadSync$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public censusModal$: Subject<boolean> = new Subject<boolean>();

    _initialLoadSync: { status: string; progress: string };
    constructor(private http: HttpClient, private datePipe: DatePipe) {}

    set initialLoadSync(status: { status: string; progress: string }) {
        this._initialLoadSync = status;
        this.initialLoadSync$.next(true);
    }

    get initialLoadSync() {
        return this._initialLoadSync;
    }

    getInitialImportLogs(facilityId: string): Observable<InitialImportLogs[]> {
        return this.http.get(`${baseUrl}/logs/import/${facilityId}`) as Observable<InitialImportLogs[]>;
    }

    getCensusFeedLogs(
        facilityId: string,
        pageSize: number,
        pageNo: number,
        sort: string,
        searchPatient: string,
        searchResident: string
    ): Observable<any> {
        //&searchPatient=emi&searchResident=51&sort=-event
        return this.http.get(
            `${baseUrl}/logs/census-feed/${facilityId}?limit=${pageSize}&page=${pageNo}&sort=${sort}&searchPatient=${searchPatient}&searchResident=${searchResident}`
        ) as Observable<any>;
    }

    getExportLogs(facilityId: string): Observable<ExportLogs[]> {
        return this.http.get(`${baseUrl}/logs/export/${facilityId}`) as Observable<ExportLogs[]>;
    }

    getInitialLoadStatus(facilityId: string): Observable<MigrationStatuses> {
        return this.http.get(`${baseUrl}/sync-status/${facilityId}`) as Observable<MigrationStatuses>;
    }

    getInitialLoadSyncProgress(facilityId: string): Observable<any> {
        return this.http.get(`${baseUrl}/sync-progress/${facilityId}`);
    }

    enableExport(facilityId, state: boolean): Observable<any> {
        return this.http.post(`${baseUrl}/export/${facilityId}`, { status: state });
    }
    getDuplicatePatient(actualPayload: any, checkPayorDiff = false): Observable<any> {
        const payload = checkPayorDiff ? { actualPayload, checkPayorDiff } : actualPayload;
        return this.http.post(`${environment.apiUrl}/patient/duplicates`, payload);
    }
    getLastA03LogByResident(residentId: string): Observable<any> {
        return this.http.get(`${environment.apiUrl}/migration/get-log/${residentId}`);
    }
    resolveConflict(id: string, payload: any): Observable<any> {
        return this.http.post(`${environment.apiUrl}/migration/resolve/${id}`, payload);
    }
    initiateInitialSync(facilityID: string, facilityOID) {
        const body = JSON.stringify({
            facilityOID,
            facilityID,
        });
        return this.http.post(`https://integration.innovahealth.app/api/v1/matrix-care/export/adt`, body, {
            headers: { 'Content-Type': 'text/xml' },
        });
    }
    downloadAncillaryChargesXML(facilityID: string): Observable<Blob> {
        return this.http.get(`${environment.apiUrl}/migration/logs/ancillary-xml/${facilityID}`, {
            responseType: 'blob',
        });
    }

    getMigrationLogCols(): ColDef[] {
        return [
            {
                headerName: 'Patient',
                field: 'patient',
                filter: true,
                floatingFilter: true,
                suppressMenu: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
            },
            {
                headerName: 'Resident ID',
                field: 'residentId',
                filter: true,
                floatingFilter: true,
                suppressMenu: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
            },
            {
                headerName: 'SSN',
                valueGetter: (params: any) => {
                    if (params?.data === undefined) return '';
                    if (params.data.actualPayload && params.data.actualPayload.SSN) {
                        return params.data.actualPayload.SSN;
                    } else {
                        return 'N/A'; // Return a default value if SSN is not available
                    }
                },
            },
            {
                headerName: 'Synced At',
                field: 'syncedAt',
                valueFormatter: (params) => {
                    return this.datePipe.transform(params.value, 'short');
                },
                sortable: true,
                sort: 'desc',
                width: 240,
            },
            {
                headerName: 'Personal Information',
                field: 'personalInformationMigration',
                cellRenderer: (params) => {
                    if (params.data === undefined) {
                        return '';
                    }
                    return params.value === 'error'
                        ? '<div class="cross-mark btn-danger"> <span class="material-symbols-outlined text-white ">' +
                              'close' +
                              '</span> </div>'
                        : '<div class="check-mark btn-success"> <span class="material-symbols-outlined text-white ">' +
                              'done' +
                              '</span> </div>';
                },
            },
            {
                headerName: 'Facility Information',
                field: 'facilityInformationMigration',
                cellRenderer: (params) => {
                    if (params.data === undefined) {
                        return '';
                    }
                    return params.value === 'error'
                        ? '<div class="cross-mark btn-danger"> <span class="material-symbols-outlined text-white ">' +
                              'close' +
                              '</span> </div>'
                        : '<div class="check-mark btn-success"> <span class="material-symbols-outlined text-white ">' +
                              'done' +
                              '</span> </div>';
                },
            },
            // { *Disable Payor Feed - Feature Request
            //     headerName: 'Payor Information',
            //     field: 'payorInformationMigration',
            //     cellRenderer: (params) => {
            //         if (params.data === undefined) {
            //             return '';
            //         }
            //         if (params.value === 'warning') {
            //             return (
            //                 '<div class="caution-mark btn-warning"> <span class="material-symbols-outlined text-white ">' +
            //                 'warning' +
            //                 '</span> </div>'
            //             );
            //         } else if (params.value === 'error') {
            //             return (
            //                 '<div class="cross-mark btn-danger"> <span class="material-symbols-outlined text-white ">' +
            //                 'close' +
            //                 '</span> </div>'
            //             );
            //         } else {
            //             return (
            //                 '<div class="check-mark btn-success"> <span class="material-symbols-outlined text-white ">' +
            //                 'done' +
            //                 '</span> </div>'
            //             );
            //         }
            //     },
            // },
            {
                headerName: 'Physician Information',
                field: 'physicianInformationMigration',
                cellRenderer: (params) => {
                    if (params.data === undefined) {
                        return '';
                    }
                    return params.value === 'error' || params.value === 'warning'
                        ? '<div class="cross-mark btn-danger"> <span class="material-symbols-outlined text-white ">' +
                              'close' +
                              '</span> </div>'
                        : '<div class="check-mark btn-success"> <span class="material-symbols-outlined text-white ">' +
                              'done' +
                              '</span> </div>';
                },
            },
            {
                headerName: 'Sync Remarks',
                field: 'syncRemarks',
                valueFormatter: (params) => {
                    if (params?.data === undefined) return '';
                    return params.value.join(', ');
                },
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                width: 600,
            },
        ];
    }

    getExportCols(): ColDef[] {
        return [
            {
                headerName: 'Event',
                field: 'event',
                filter: true,
                floatingFilter: true,
                suppressMenu: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                minWidth: 280,
            },
            {
                headerName: 'Patient',
                field: 'patient',
                filter: true,
                floatingFilter: true,
                suppressMenu: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
            },
            {
                headerName: 'Resident ID',
                field: 'residentId',
                filter: true,
                floatingFilter: true,
                suppressMenu: true,
                floatingFilterComponentParams: { suppressFilterButton: true },
                width: 120,
            },
            {
                headerName: 'Synced At',
                field: 'syncedAt',
                valueFormatter: (params) => {
                    return this.datePipe.transform(params.value, 'short');
                },
                sortable: true,
                sort: 'desc',
                width: 150,
            },
            {
                headerName: 'Sync Remarks',
                field: 'syncRemarks',
                valueFormatter: (params) => {
                    return params.value.join(', ');
                },
                autoHeight: true,
                wrapText: true,
                cellStyle: () => {
                    return { wordBreak: 'normal' };
                },
                width: 300,
            },
            {
                headerName: 'Actions',
                cellRendererParams: { gridName: 'export' },
                cellRendererSelector: (params) => {
                    if (params.data.event === IntegrationEvents.ADD_ANCILLARY_CHARGE) {
                        return {
                            component: ActionsCellRendererComponent,
                            params: {
                                download: true,
                                tooltipText: IntegrationEvents.DOWNLOAD_XML,
                            },
                        };
                    }
                },
            },
        ];
    }
}
