export enum SocketEvents {
    IDENTITY = 'identity',
    NOTIFICATION = 'Notification',
    FORCE_LOGOUT = 'force logout',
    GOLIVE = 'GoLive',
    TIME_TRACKING = 'timeTracking',
}

export const IntegrationEvents = {
    DOWNLOAD: 'download',
    DOWNLOAD_XML: 'Download XML',
    ADD_ANCILLARY_CHARGE: 'Add Ancillary Charge',
};
