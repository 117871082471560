import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, of } from 'rxjs';
import { DailyNote, DetailedCptCodes, OtherModeMinutes } from '@app/interfaces';

const baseUrl = `${environment.apiUrl}/cpt-codes`;
@Injectable({
    providedIn: 'root',
})
export class CptCodesService {
    unTimedCodes: string[] = [
        '97150',
        '92507',
        '92508',
        '92521',
        '92522',
        '92523',
        '92524',
        '92526',
        '92597',
        '92609',
        '97012',
        '97016',
        '97018',
        '97022',
        '97024',
        '97028',
        '97161',
        '97162',
        '97163',
        '97164',
        '97165',
        '97166',
        '97167',
        '97168',
        'G0281',
        'G0283',
        'G0329',
        '92610',
        '96105',
        '97550',
        '97552',
        '92612',
        '20560',
        '20561',
        '90901',
        '92520',
        '92605',
        '92606',
        '92607',
        '92608',
        '92611',
        '92613',
        '92614',
        '92616',
        '92618',
        '95851',
        '95852',
        '95992',
        '96112',
        '96113',
        '96125',
        '97001',
        '97002',
        '97003',
        '97004',
        '97010',
        '97014',
        '97026',
        '97597',
        '97598',
        '97602',
        '97605',
        '97606',
        '97607',
        '97608',
        '97610',
        '97750',
        '97755',
        '97760',
        '97761',
        '97763',
        '97799',
        '98966',
        '98967',
        '98968',
        '98970',
        '98971',
        '98972',
        'G0451',
        'G2250',
        'G2251',
        'G2252',
        '99453',
        '99454',
        '99457',
        '99458',
        '31579',
        'G0541',
        'G0543',
    ];
    careGiverTrainingCodes = ['G0542', '97551'];
    protected __teleHealthVisit: boolean;
    RVU = {
        '20560': 0.32,
        '20561': 0.48,
        '90901': 0.41,
        '90912': 0.9,
        '90913': 0.5,
        '92507': 1.3,
        '92508': 0.33,
        '92520': 0.75,
        '92521': 2.24,
        '92522': 1.92,
        '92523': 3.84,
        '92524': 1.92,
        '92526': 1.34,
        '92597': 1.26,
        '92607': 1.85,
        '92608': 0.7,
        '92609': 1.5,
        '92610': 1.3,
        '92611': 1.34,
        '92612': 1.27,
        '92614': 1.27,
        '92616': 1.88,
        '95851': 0.16,
        '95852': 0.11,
        '95992': 0.75,
        '96105': 1.75,
        '96112': 2.56,
        '96113': 1.16,
        '96125': 1.7,
        '97012': 0.25,
        '97016': 0.18,
        '97018': 0.06,
        '97022': 0.17,
        '97024': 0.06,
        '97026': 0.06,
        '97028': 0.08,
        '97032': 0.25,
        '97033': 0.26,
        '97034': 0.21,
        '97035': 0.21,
        '97036': 0.28,
        '97110': 0.45,
        '97112': 0.5,
        '97113': 0.48,
        '97116': 0.45,
        '97124': 0.35,
        '97129': 0.5,
        '97130': 0.48,
        '97140': 0.43,
        '97150': 0.29,
        '97161': 1.54,
        '97162': 1.54,
        '97163': 1.54,
        '97164': 0.96,
        '97165': 1.54,
        '97166': 1.54,
        '97167': 1.54,
        '97168': 0.96,
        '97530': 0.44,
        '97533': 0.48,
        '97535': 0.45,
        '97537': 0.48,
        '97542': 0.48,
        '97545': 0,
        '97546': 0,
        '97597': 0.77,
        '97598': 0.5,
        '97605': 0.55,
        '97606': 0.6,
        '97607': 0.41,
        '97608': 0.46,
        '97610': 0.4,
        '97750': 0.45,
        '97755': 0.62,
        '97760': 0.5,
        '97761': 0.5,
        '97763': 0.48,
        '98966': 0.25,
        '98967': 0.5,
        '98968': 0.75,
        '98970': 0.25,
        '98971': 0.44,
        '98972': 0.69,
        '99453': 0,
        '99454': 0,
        '99457': 0.61,
        '99458': 0.61,
        G0281: 0.18,
        G0283: 0.18,
        G0329: 0.06,
        G0451: 0,
        G2250: 0.18,
        G2251: 0.25,
        G0541: 1,
        G0542: 0.54,
        G0543: 0.23,
    };

    allTherapists = null;
    allTherapistsUnformatted = null;

    constructor(private http: HttpClient) {
        this.__teleHealthVisit = false;
    }

    searchExercisesInCptCode(searchText: string, cptCode: string) {
        if (!searchText || searchText.length < 3) {
            return of({ message: 'type more chars' });
        }

        return this.http
            .get<any>(`${baseUrl}/${cptCode}/search-exercises/${searchText}`, { withCredentials: true })
            .pipe(
                map((exercises) => {
                    return exercises.data;
                })
            );
    }

    minimumMinsRequiredCheck(cptCode: DetailedCptCodes) {
        switch (cptCode.code) {
            case 'G0541':
            case '97550':
                return cptCode.cptMinutes >= 30;
            default:
                return true;
        }
    }

    // including timed and unTimed codes
    calculateTotalUnits(detailedCPTCodes: any[]): number {
        // count no more than 1 unit for each unTimed Code
        const unTimedUnits = detailedCPTCodes
            .filter(
                (elem) =>
                    this.unTimedCodes.includes(elem.code) &&
                    this.minimumMinsRequiredCheck(elem) &&
                    (parseInt(elem.cptMinutes, 10) || 0) > 0
            )
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .reduce((acc, curr) => acc + 1, 0);

        const totalTimedMinutes = detailedCPTCodes
            .filter(
                (elem) => !this.unTimedCodes.includes(elem.code) && !this.careGiverTrainingCodes.includes(elem.code)
            )
            .reduce((acc, curr) => {
                return acc + (parseInt(curr.cptMinutes, 10) || 0);
            }, 0);

        const careGiverTrainingUnits = detailedCPTCodes
            .filter((elem) => this.careGiverTrainingCodes.includes(elem.code))
            .reduce((acc, curr) => {
                return acc + Math.floor(parseInt(curr.cptMinutes ?? 0, 10) / 15);
            }, 0);

        const timedUnits = this.calculateUnits(totalTimedMinutes) ?? 0;

        return timedUnits + unTimedUnits + careGiverTrainingUnits;
    }

    calculateIndividualUnits(detailedCPTCodes: any[]) {
        const codeUnitIndex = {};

        if (!detailedCPTCodes || !detailedCPTCodes.length) {
            return codeUnitIndex;
        }
        // ! not use anywhere
        // const unTimedUnits = detailedCPTCodes
        detailedCPTCodes
            .filter(
                (elem) =>
                    (this.unTimedCodes.includes(elem.code) || this.careGiverTrainingCodes.includes(elem.code)) &&
                    this.minimumMinsRequiredCheck(elem) &&
                    (parseInt(elem.cptMinutes, 10) || 0) > 0
            )
            .forEach((elem) => {
                if (this.careGiverTrainingCodes.includes(elem.code)) {
                    codeUnitIndex[elem.code] = Math.floor(parseInt(elem.cptMinutes ?? 0, 10) / 15);
                } else {
                    codeUnitIndex[elem.code] = 1;
                }
            });

        const timedCodes = detailedCPTCodes.filter(
            (elem) => !this.unTimedCodes.includes(elem.code) && !this.careGiverTrainingCodes.includes(elem.code)
        );

        const totalTimedMinutes = timedCodes.reduce((acc, curr) => {
            return acc + (parseInt(curr.cptMinutes, 10) || 0);
        }, 0);

        let unitsToDistribute = this.calculateUnits(totalTimedMinutes) ?? 0;

        const records = [];
        timedCodes.forEach((elem) => {
            if (elem.cptMinutes) {
                const cptMinutes = parseInt(elem.cptMinutes, 10) || 0;
                let unitsUsed = parseInt((cptMinutes / 15).toString(), 10);
                const minLeft = cptMinutes % 15;

                // update the remaining units to distribute
                if (unitsToDistribute < unitsUsed) {
                    unitsUsed = unitsToDistribute;
                    unitsToDistribute = 0;
                } else {
                    unitsToDistribute = unitsToDistribute - unitsUsed;
                }

                records.push({
                    totalMinutes: elem.cptMinutes,
                    code: elem.code,
                    unitsUsed,
                    minLeft,
                    RVU: this.RVU[elem.code],
                });
            }
        });

        this.sortByAttribute(records, 'minLeft', 'desc');

        // sorted by min-left and RVU
        let sorted = [];

        const processed = {};

        records.forEach((elem, index1) => {
            if (!processed[elem.minLeft]) {
                if (records.find((curr, index2) => index1 !== index2 && curr.minLeft === elem.minLeft)) {
                    // duplicate exists
                    processed[elem.minLeft] = true;
                    const duplicates = [];
                    const temp = records.slice(index1, records.length);

                    temp.forEach((curr) => {
                        if (curr.minLeft === elem.minLeft) {
                            duplicates.push(curr);
                        }
                    });

                    this.sortByAttribute(duplicates, 'RVU', 'desc');

                    sorted = [...sorted, ...duplicates];
                } else {
                    sorted.push(elem);
                }
            }
        });

        // data sorted now in the 'sorted' array
        const additionalUnits = sorted.slice(0, unitsToDistribute);

        additionalUnits.forEach((elem) => {
            sorted.forEach((curr) => {
                if (curr.code === elem.code) {
                    curr.unitsUsed++;
                }
            });
        });

        sorted.forEach((elem) => {
            codeUnitIndex[elem.code] = elem.unitsUsed;
        });

        return codeUnitIndex;
    }

    sortByAttribute(records: any[], attribute: string, order = 'asc') {
        records.sort(function (a, b) {
            if (order === 'asc') {
                return a[attribute] - b[attribute];
            }
            return b[attribute] - a[attribute];
        });
    }

    calculateUnits(minutes: number): number {
        if (!minutes) {
            return null;
        }

        if (minutes < 8) {
            return 0;
        } else if (minutes <= 22) {
            return 1;
        } else if (minutes <= 37) {
            return 2;
        } else if (minutes <= 52) {
            return 3;
        } else if (minutes <= 67) {
            return 4;
        } else if (minutes <= 82) {
            return 5;
        } else if (minutes <= 97) {
            return 6;
        } else if (minutes <= 112) {
            return 7;
        } else if (minutes <= 127) {
            return 8;
        }

        return 8;
    }

    grandTotalMinutes(cptData): void {
        return cptData.reduce((acc, row) => {
            const rowSum = typeof row.cptMinutes === 'string' ? parseInt(row.cptMinutes) : row.cptMinutes;
            return rowSum ? acc + rowSum : acc;
        }, 0);
    }

    /**
     * Return true if
     * - at least one Individual cpt code is added with time assigned.
     * - all the individual cpt codes have minutes assigned to them.
     * @function
     * @param {DailyNote} dailyNote - the daily note object.
     */
    individualMinutesValid(dailyNote: DailyNote) {
        if (
            !dailyNote ||
            !dailyNote.objectiveAssessment ||
            !dailyNote.objectiveAssessment.detailedCptCodes ||
            !dailyNote.objectiveAssessment.detailedCptCodes.length
        ) {
            return false;
        }

        const detailedCptCodes = dailyNote.objectiveAssessment.detailedCptCodes;

        // check:2 all the individual cpt codes have minutes and Justification assigned to them.
        const emptyTimeObjExists = detailedCptCodes.find(
            (item) =>
                item.cptMinutes == null ||
                item.cptMinutes === 0 ||
                item.justification == null ||
                item.justification === ''
        );

        if (emptyTimeObjExists) {
            return false;
        }

        // check3: No single service can have more than 6 units
        const unitsDistribution = this.calculateIndividualUnits(detailedCptCodes);
        let greaterThanSixUnits = false;
        if (unitsDistribution) {
            Object.keys(unitsDistribution).forEach((code) => {
                if (unitsDistribution[code] > 6) {
                    greaterThanSixUnits = true;
                }
            });
        }
        if (greaterThanSixUnits) return false;

        return true;
    }

    /**
     * Return true if
     * - otherModeMinutes doesn't exist
     * - if otherModeMinutes exist, grand total of individual time
     *   MUST BE GREATER than grand total of 'OtherTx Minutes'
     * @function
     * @param {DailyNote} dailyNote - the daily note object.
     * @param {DetailedCptCodes} detailedCptCodes - array having individual cpt codes.
     */
    otherTxValid(dailyNote: DailyNote, detailedCptCodes: DetailedCptCodes[]) {
        const otherModeMinutes = dailyNote.objectiveAssessment.otherModeMinutes;
        if (!otherModeMinutes) {
            return true;
        }

        if (this.sumOtherTxGreaterThanMDSCpt(otherModeMinutes, detailedCptCodes)) {
            return false;
        }

        if (this.concurrentGreaterThanMDSCodesMinutes(otherModeMinutes, detailedCptCodes)) {
            return false;
        }

        return true;
    }

    /**
     * Return true if
     * - otherModeMinutes doesn't exist
     * - if otherModeMinutes exist, grand total of individual time
     *   MUST BE GREATER than grand total of 'OtherTx Minutes'
     * @function
     * @param {OtherModeMinutes} otherModeMinutes - otherTxMinutes object having coTreat, concurrent or more (in future).
     * @param {DetailedCptCodes} detailedCptCodes - array having individual cpt codes.
     */
    sumOtherTxGreaterThanMDSCpt(otherModeMinutes: OtherModeMinutes, detailedCptCodes: DetailedCptCodes[]) {
        const otherTxSum = Object.keys(otherModeMinutes).reduce(
            (acc, key) => (+otherModeMinutes[key].minutes ? acc + +otherModeMinutes[key].minutes : acc),
            0
        );

        const MDSMinutesSum = Object.keys(detailedCptCodes).reduce(
            (acc, key) =>
                +detailedCptCodes[key].cptMinutes &&
                detailedCptCodes[key].isMds &&
                detailedCptCodes[key].code !== '97150' &&
                detailedCptCodes[key].code !== '92508'
                    ? acc + +detailedCptCodes[key].cptMinutes
                    : acc,
            0
        );

        if (otherTxSum > MDSMinutesSum) {
            return true;
        }

        return false;
    }

    /**
     * @function
     * @param {OtherModeMinutes} otherModeMinutes - otherTxMinutes object having coTreat, concurrent or more (in future).
     * @param {DetailedCptCodes} detailedCptCodes - array having individual cpt codes.
     */
    concurrentGreaterThanMDSCodesMinutes(otherModeMinutes: OtherModeMinutes, detailedCptCodes: DetailedCptCodes[]) {
        const concurrentMinutes = +otherModeMinutes['concurrent']?.minutes;

        const MDSMinutesSum = Object.keys(detailedCptCodes).reduce(
            (acc, key) =>
                +detailedCptCodes[key].cptMinutes &&
                detailedCptCodes[key].isMds &&
                detailedCptCodes[key].code !== '97150' &&
                detailedCptCodes[key].code !== '92508'
                    ? acc + +detailedCptCodes[key].cptMinutes
                    : acc,
            0
        );

        if (concurrentMinutes > MDSMinutesSum) {
            return true;
        }

        return false;
    }

    setTeleHealthVisit(value: boolean) {
        this.__teleHealthVisit = value;
    }

    getTeleHealthVisit(): boolean {
        return this.__teleHealthVisit;
    }
}
