import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getWeekEndDate, getWeekStartDate } from '@app/helpers/utils';
import { filterNotesByDateRange } from '@app/helpers/utils/data.utils';
import { Scheduler } from '@app/interfaces';
import { MinutesToHoursMinutesPipe } from '@app/pipes';
import {
    DocumentationService,
    FacilityManagementService,
    PrintService,
    SchedulerService,
    SchedulerV2Service,
    StoreService,
} from '@app/services';
import { SchedulerCalendarState } from '@app/store/store.interface';
import { CaseloadNoteType } from '@app/interfaces/documentation';
import { Store } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { cloneDeep, sortBy } from 'lodash';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'app-therapist-case-load',
    templateUrl: './therapist-case-load.component.html',
    styleUrls: ['./therapist-case-load.component.scss'],
    providers: [MinutesToHoursMinutesPipe],
})
export class TherapistCaseLoadComponent implements OnDestroy {
    private readonly onDestroy = new Subject<void>();

    therapistIds: string[];
    columnDefs: ColDef[] = [];
    rowData = [];
    sortCaseload: any = {};
    therapistDetails: any[] = [];
    scheduleDate = '';
    therapists: any = {};
    subscriptions: Subscription = new Subscription();
    dateLabel = ''; // used when print from therapist caseload for week view
    therapistCaseloadFilters: any;
    caseloadNoteType: any;
    constructor(
        router: Router,
        route: ActivatedRoute,
        private printService: PrintService,
        private storeService: StoreService,
        private schedulerService: SchedulerService,
        private schedulerV2Service: SchedulerV2Service,
        private minutesHoursPipe: MinutesToHoursMinutesPipe,
        private facilityManagementService: FacilityManagementService,
        private documentationService: DocumentationService,
        private store: Store<{
            scheduler: Scheduler;
            schedulerCalendar: SchedulerCalendarState;
        }>
    ) {
        this.sortCaseload = this.documentationService.getSortedCaseload();
        this.scheduleDate = route.snapshot.queryParamMap.get('date');
        this.therapistIds = route.snapshot.params['therapistIds']?.split(',');
        this.caseloadNoteType = CaseloadNoteType;
        if (this.therapistIds && this.therapistIds.length > 0) {
            this.schedulerV2Service
                .getSchedulerData(
                    this.facilityManagementService._currentFacility.id,
                    getWeekStartDate(new Date(this.scheduleDate))
                )
                .pipe(takeUntil(this.onDestroy))
                .subscribe((result: any) => {
                    this.storeService.setSchedulerCalendar(new Date(this.scheduleDate));
                    result.data.patients = filterNotesByDateRange(
                        result.data.patients,
                        getWeekStartDate(new Date(this.scheduleDate)),
                        getWeekEndDate(new Date(this.scheduleDate))
                    );
                    this.storeService.setScheduler(result.data);
                    this.storeService.setLoadingState(false);
                    this.therapists = result.data.therapists;
                    this.mapRowData(this.therapists, this.therapistIds);
                });
        } else {
            const routerStateData = router.getCurrentNavigation()?.extras?.state?.therapist;
            if (router.getCurrentNavigation()?.extras?.state?.dateLabel) {
                this.dateLabel = router.getCurrentNavigation()?.extras?.state?.dateLabel;
            }
            this.therapistCaseloadFilters = router.getCurrentNavigation()?.extras?.state?.therapistCaseloadFilters;
            if (routerStateData) {
                this.therapists = routerStateData;
                this.mapTherapistDetails(routerStateData instanceof Array ? routerStateData : [routerStateData]);
            }
        }
    }
    mapRowData(therapists, therapistIds) {
        this.rowData = [];
        therapists.forEach((d) => {
            const therapistId = therapistIds.find((i) => i == d._id);
            if (therapistId) {
                const noOfPatients = this.dailyTherapistData(d);
                const caseload = this.therapistCaseload(d);
                noOfPatients &&
                    this.rowData.push({
                        id: d._id,
                        name: `${d.user.lastName}, ${d.user.firstName}`,
                        email: d.user.email,
                        discipline: d.discipline,
                        noOfPatients: noOfPatients,
                        caseload: caseload,
                        currentFacility: this.facilityManagementService._currentFacility,
                    });
            }
        });

        if (this.rowData && this.rowData.length) {
            this.therapists = this.rowData;
            this.mapTherapistDetails(this.rowData instanceof Array ? this.rowData : [this.rowData]);
        }
    }

    dailyTherapistData(therapist) {
        let notesCount = 0;
        for (const availability of therapist.availability) {
            if (availability.caseloads.length > 0) {
                const availabilityCaseloads = availability.caseloads.find(
                    (caseLoad) =>
                        new Date(caseLoad.day).setHours(0, 0, 0, 0) == new Date(this.scheduleDate).setHours(0, 0, 0, 0)
                );
                notesCount = availabilityCaseloads
                    ? availabilityCaseloads.notes.eval?.length +
                      availabilityCaseloads.notes.dailyNotes?.length +
                      availabilityCaseloads.notes.progressNotes?.length +
                      availabilityCaseloads.notes.dischargeNotes?.length
                    : 0;
            }
        }
        return notesCount;
    }
    therapistCaseload(therapist) {
        let caseloads = null;
        for (const availability of therapist.availability) {
            if (availability.caseloads.length > 0) {
                const availabilityCaseloads = availability.caseloads.find(
                    (caseLoad) =>
                        new Date(caseLoad.day).setHours(0, 0, 0, 0) == new Date(this.scheduleDate).setHours(0, 0, 0, 0)
                );
                if (availabilityCaseloads) {
                    caseloads = availabilityCaseloads;
                }
            }
        }
        return caseloads;
    }
    mapTherapistDetails(therapists: any[]) {
        const caseloadIds = therapists.map((therapist: any) => therapist.caseload._id);
        this.schedulerV2Service
            .caseloadPrint({ ids: caseloadIds })
            .pipe(takeUntil(this.onDestroy))
            .subscribe({
                next: (result: any) => {
                    for (let index = 0; index < therapists.length; index++) {
                        therapists[index].caseload = cloneDeep(
                            result.data.find((caseload: any) => caseload._id == therapists[index].caseload._id)
                        );
                        therapists[index].caseload.patientNotes = cloneDeep(
                            this.buildNotes(therapists[index].caseload)
                        );
                        // Setting no of patients for each therapist caseload
                        therapists[index].noOfPatients = therapists[index].caseload.patientNotes.filter(
                            (note: any) => note.noteType !== CaseloadNoteType.BlockedTime
                        ).length;
                    }
                    this.therapists = therapists;
                    // Sorting the patient on each therapist based on appointment time or planned minutes
                    this.sortEachTherapistPatients();
                    this.sortCaseloadDate();
                    window.onafterprint = this.afterPrint;
                    setTimeout(() => {
                        print();
                    }, 0);
                },
                error: (v) => {
                    console.log(v);
                },
            });
    }
    afterPrint(): void {
        window.history.back();
    }
    /**
     * Utility function to each therapist patients
     */
    sortEachTherapistPatients(): void {
        for (const therapist of this.therapists) {
            this.sortPatientsOnCaseload(therapist);
        }
    }
    /**
     * This function will sort all the patient on the given therapist based on the appointment time or planned minutes
     */
    sortPatientsOnCaseload(therapist: any): void {
        therapist.caseload.patientNotes = sortBy(therapist.caseload.patientNotes, (patient) => {
            // Extract name (if exists), otherwise use "Blocked Time"
            const patientName = patient.docId?.facilityAdmission?.patient?.lastName || CaseloadNoteType.BlockedTime;

            // Extract startTime based on note type
            const startTime =
                patient.noteType === CaseloadNoteType.BlockedTime
                    ? patient.startTime
                    : patient.docId?.appointment?.startTime;

            const timeValue = startTime ? new Date(startTime).getTime() : Infinity;
            return [patientName, timeValue];
        }).sort((a, b) => {
            const aName = a.docId?.facilityAdmission?.patient?.lastName || CaseloadNoteType.BlockedTime;
            const bName = b.docId?.facilityAdmission?.patient?.lastName || CaseloadNoteType.BlockedTime;

            // 1. Sort by patient name (A-Z order)
            const nameCompare = aName.localeCompare(bName);
            if (nameCompare !== 0) return nameCompare;

            // 2. Extract appointment times
            const aHasStartTime =
                a.noteType === CaseloadNoteType.BlockedTime ? a.startTime : a.docId?.appointment?.startTime;
            const bHasStartTime =
                b.noteType === CaseloadNoteType.BlockedTime ? b.startTime : b.docId?.appointment?.startTime;

            // 3. If patient name is the same, sort by appointmentTime (earlier first)
            if (aHasStartTime && bHasStartTime) {
                return new Date(aHasStartTime).getTime() - new Date(bHasStartTime).getTime();
            }

            // 4. Entry with appointmentTime comes first
            if (aHasStartTime && !bHasStartTime) return -1;
            if (!aHasStartTime && bHasStartTime) return 1;

            // 5. If both don't have appointmentTime, sort by plannedTx (higher first)
            return b.plannedTx - a.plannedTx;
        });
    }
    /**
     * In week view, this method will sort the therapist based on the date in ascending order
     */
    sortCaseloadDate(): void {
        this.therapists = this.therapists.sort((a, b) => {
            const dateA = new Date(a.caseload?.day);
            const dateB = new Date(b.caseload?.day);

            // Check if both dates are valid
            if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
                return dateA.getTime() - dateB.getTime(); // Sort by date
            } else {
                return 0; // If either date is invalid, treat them as equal
            }
        });
    }
    checkDescriptionExists(descObjects: any) {
        if (!descObjects || descObjects.length == 0) {
            return false;
        } else {
            return descObjects.find((descObject) => descObject.description);
        }
    }
    buildNotes(caseload) {
        if (this.therapistCaseloadFilters) {
            // TODO: Not implemented yet, will fix it with UI
            // this.appliedFilter(caseload);
        }
        const notes = [];
        if (caseload.notes?.progressNotes) {
            for (const progressNote of caseload.notes.progressNotes) {
                notes.push({ noteType: 'Progress Note', ...progressNote });
            }
        }
        if (caseload.notes?.dailyNotes) {
            for (const dailyNote of caseload.notes.dailyNotes) {
                notes.push({ noteType: 'Daily Note', ...dailyNote });
            }
        }
        if (caseload.notes?.eval) {
            for (const evaluation of caseload.notes.eval) {
                notes.push({ noteType: 'Evaluation', ...evaluation });
            }
        }
        if (caseload.notes?.dischargeNotes) {
            for (const dischargeNote of caseload.notes.dischargeNotes) {
                notes.push({ noteType: 'Discharge Note', ...dischargeNote });
            }
        }
        if (caseload.notes?.recertificationNotes) {
            for (const recertNote of caseload.notes.recertificationNotes) {
                notes.push({ noteType: 'Recertification Note', ...recertNote });
            }
        }
        if (caseload.notes?.timeBlocks) {
            for (const timeBlock of caseload.notes.timeBlocks) {
                notes.push({ noteType: CaseloadNoteType.BlockedTime, ...timeBlock });
            }
        }
        return notes;
    }

    calculatePlannedMinutes(weekFrequency): number {
        const found = weekFrequency.find(
            (wf) => new Date(wf.day?.day).setHours(0, 0, 0, 0) === new Date(this.scheduleDate).setHours(0, 0, 0, 0)
        );
        if (found) return found.day.individual?.planned + found.day.concurrent?.planned + found.day.group?.planned;
        else return 0;
    }

    appliedFilter(caseload) {
        if (this.therapistCaseloadFilters.documentType != 'All') {
            let docType =
                this.therapistCaseloadFilters.documentType == 'Evaluation'
                    ? 'eval'
                    : this.therapistCaseloadFilters.documentType.replace(' ', '') + 's';
            docType = docType.charAt(0).toLowerCase() + docType.slice(1);
            for (const key in caseload.notes) {
                // Clear all arrays except the one matching the docType
                if (key.toLowerCase() !== docType.toLowerCase() && key != 'timeBlocks') {
                    caseload.notes[key] = [];
                }
            }
            caseload.notes[docType] = caseload.notes[docType].filter((note) => {
                // Need to match whole name without comma and spaces
                const patientFullName = note.docId.facilityAdmission.patient
                    ? note.docId.facilityAdmission.patient?.lastName +
                      ', ' +
                      note.docId.facilityAdmission.patient?.firstName
                    : 'N/A';
                return (
                    (this.therapistCaseloadFilters.docStatus != 'All'
                        ? note.docId.status.includes(this.therapistCaseloadFilters.docStatus)
                        : true) &&
                    (this.therapistCaseloadFilters.visitStatus != 'All'
                        ? note.visitStatus.toLocaleLowerCase() ==
                          this.therapistCaseloadFilters.visitStatus.toLocaleLowerCase()
                        : true) &&
                    (this.therapistCaseloadFilters.patientName
                        ?  this.flatString(patientFullName).includes(this.flatString(this.therapistCaseloadFilters.patientName))
                        : true)
                );
            });
        } else {
            for (const key in caseload.notes) {
                // Clear all arrays except the one matching the docType
                if (key != 'timeBlocks') {
                    caseload.notes[key] = caseload.notes[key].filter((note) => {
                        const patientFullName = note.docId.facilityAdmission.patient
                            ? note.docId.facilityAdmission.patient?.lastName +
                              ', ' +
                              note.docId.facilityAdmission.patient?.firstName
                            : 'N/A';
                        return (
                            (this.therapistCaseloadFilters.docStatus != 'All'
                                ? note.docId.status.includes(this.therapistCaseloadFilters.docStatus)
                                : true) &&
                            (this.therapistCaseloadFilters.visitStatus != 'All'
                                ? note.visitStatus.toLocaleLowerCase() ==
                                  this.therapistCaseloadFilters.visitStatus.toLocaleLowerCase()
                                : true) &&
                            (this.therapistCaseloadFilters.patientName
                                ?  this.flatString(patientFullName).includes(this.flatString(this.therapistCaseloadFilters.patientName))
                                : true)
                        );
                    });
                }
            }
        }
    }
    flatString(name: string) {
        return name?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
    }

    ngOnDestroy(): void {
        if (window.onafterprint === this.afterPrint) {
            window.onafterprint = null;
        }
        this.subscriptions.unsubscribe();
        this.onDestroy.next();
    }
}
