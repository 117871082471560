import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientService } from '@app/services';

@Component({
    selector: 'app-patients-search',
    templateUrl: './patients-search.component.html',
    styleUrls: ['./patients-search.component.scss'],
})
export class PatientsSearchComponent {
    @Input() searchTerm = '';
    @Input() borderRadius = '';
    @Input() marginBottom = '';
    @Input() placeHolder = 'Search Patient';
    @Output() search = new EventEmitter();
    @Output() immediateSearch = new EventEmitter(); // needed to active loader as soon as character is typed instead of waiting to complete debounce time

    isInputFocused = false;
    clearTime: any;

    constructor(private patientService: PatientService) {}
    preventChar($event) {
        if ($event.which == 13) {
            $event.preventDefault();
            $event.stopPropagation();
        }
    }
    debounce($event) {
        this.immediateSearch.emit($event); // needed to active loader as soon as character is typed instead of waiting to complete debounce time

        if (this.clearTime) {
            clearTimeout(this.clearTime);
        }
        this.clearTime = setTimeout(() => this.setSearchTerm($event), 1100);
    }
    setSearchTerm(value) {
        this.search.emit(value);
    }
    onInputFocus() {
        this.isInputFocused = true;
    }

    onInputBlur() {
        this.isInputFocused = false;
    }
}
